import * as auth from '@/utils/auth'
const state = {
    userInfo: null,
    token: auth.getToken(),
    mid: auth.getMid(),
    terminalCode:auth.getTerminalCode()
}
const getters = {
    userInfo(state) {
        return state.userInfo
    },
    token(state) {
        return state.token
    },
    mid(state){
        return state.mid
    },
    terminalCode(state){
        return state.terminalCode
    },

}
const mutations = {
    setActiveManue(state, idx) {
        state.activeManue = idx
    },
    login(state, { userInfo, token }) {
        state.userInfo = userInfo;
        state.mid = userInfo.mid;
        state.token = token;
        auth.setToken(token);
        auth.setMid(userInfo.mid);
    },
    logout(state){
        state.userInfo = null;
        state.token = null;
        state.mid = null;
        auth.removeToken();
        auth.removeMid();
    },
    setUserInfo(state,data) {
        state.userInfo = data;
    }
}
export default {
    state,
    getters,
    mutations
}
