import Vue from 'vue'
import Vuex from 'vuex'
import manue from './modules/manue'
import user from './modules/user'
import evidence from './modules/evidence'
import authinfo from './modules/authinfo'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    manue,
    user,
    evidence,
	authinfo
  }
})
