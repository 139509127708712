import request from '@/utils/request'

export function register(params) {
    return request(
        'post',
        '/v1',
       `/ma/user/register/+86-${params.mobileOrEmail}`,
       params
    );
}

export function login(params) {
    params.loginName=`+86-${params.mobileOrEmail}`;
    return request(
        'post',
        '/v1',
       `/sso/login`,
       params
    );
}


export function queryUserInfo(mid) {
    return request(
        'get',
        '/v1',
       `/ma/user/${mid}`
    );
}

// 忘记密码
export function forgetPassword(params) {
    return request(
        'post',
        '/v1',
       `/ma/user/forget/password`,
       params
    );
}

// 修改密码
export function editPassword(mid,params) {
    return request(
        'post',
        '/v1',
       `/ma/user/password/${mid}`,
       params
    );
}

// 退出
export function logout() {
    return request(
        'get',
        '/v1',
       `/sso/logout`
    );
}

// 联系我们
export function reportSubmit(params) {
    return request(
        'post',
        '/v1',
       `/cms/app/report`,
       params
    );
}