import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import Antd from 'ant-design-vue';
import 'element-ui/lib/theme-chalk/index.css';
import 'ant-design-vue/dist/antd.css';
import animated from 'animate.css'
import Global from './config/global'
import dayjs from "dayjs"

// 全局注册方法
import axiosServer from './config/axios.config'

import './assets/css/common.scss'
import './assets/css/reset-plugin.scss'

Vue.use(ElementUI);
Vue.use(Antd);
Vue.use(animated)

// 全局引入消息弹窗和确认confirm盒子
import { Message, MessageBox } from 'element-ui'
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm

Object.defineProperty(Vue.prototype, 'GLOBAL', {
  value: Global
}) // 定义了全局 this.GLOBAL

Vue.use(axiosServer)

Vue.config.productionTip = false
Vue.prototype.dayjs = dayjs;//可以全局使用dayjs
Vue.prototype.bus = new Vue;


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
