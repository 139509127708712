
const state = {
    evidencePaymentPrice: null,
    vipConfig:null,
    vipInfo:null,
}
const getters = {
    evidencePaymentPrice(state) {
        return state.evidencePaymentPrice
    },
    vipConfig(state){
        return state.vipConfig;
    },
    vipInfo(state){
        return state.vipInfo;
    }
}
const mutations = {
    setEvidencePaymentPrice(state, evidencePaymentPrice) {
        state.evidencePaymentPrice = evidencePaymentPrice
    },
    setVipConfig(state,vipConfig){
        state.vipConfig=vipConfig;
    },
    setVipInfo(state,vipInfo){
        state.vipInfo=vipInfo;
    }
}
export default {
    state,
    getters,
    mutations
}
