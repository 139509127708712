import request from '@/utils/request'



export function queryPaymentPrice() {
    return request(
        'get',
        '/v1',
        `/evidence/payment/price`
    );
}


export function applyExistOrder(params) {
    return request(
        'post',
        '/v1',
        `/evidence/exist`,
        params
    );
}


export function paymentExistOrder(orderNo) {
    return request(
        'post',
        '/v1',
        `/evidence/exist/payment/${orderNo}`, {}
    );
}


export function queryExistOrders(pageNumber, pageSize, params) {
    return request(
        'post',
        '/v1',
        `/evidence/exist/p/${pageNumber}-${pageSize}`,
        params
    );
}

export function queryExistOrder(orderNo) {
    return request(
        'get',
        '/v1',
        `/evidence/exist/${orderNo}`
    );
}


export function queryExistOrderItems() {
    return request(
        'get',
        '/v1',
        // 查询所有
        //    `/evidence/exist/items`
        // 查询已完成
        `/evidence/exist/items/21`
    );
}

export function queryPaymentOrder(orderNo) {
    return request(
        'get',
        '/v1',
        `/evidence/payment/order/${orderNo}`
    );
}

export function queryPaymentOrderByOutBizNp(outBizNo, bizType) {
    return request(
        'get',
        '/v1',
        `/evidence/payment/order/${outBizNo}/${bizType}`
    );
}
// 登记
export function applyRegisterOrder(params) {
    return request(
        'post',
        '/v1',
        `/evidence/register`,
        params
    );
}


export function paymentRegisterOrder(orderNo) {
    return request(
        'post',
        '/v1',
        `/evidence/register/payment/${orderNo}`, {}
    );
}

export function queryRegisterOrder(orderNo) {
    return request(
        'get',
        '/v1',
        `/evidence/register/${orderNo}`
    );
}

export function queryRegisterOrderList(pageNumber, pageSize, params) {
    return request(
        'post',
        '/v1',
        `/evidence/register/p/${pageNumber}-${pageSize}`,
        params
    );
}

// 监测
export function applyDetectOrder(params) {
    return request(
        'post',
        '/v1',
        `/evidence/detect`,
        params
    );
}

export function queryDetectOrder(orderNo) {
    return request(
        'get',
        '/v1',
        `/evidence/detect/${orderNo}`
    );
}

export function queryDetectOrderList(pageNumber, pageSize, params) {
    return request(
        'post',
        '/v1',
        `/evidence/detect/p/${pageNumber}-${pageSize}`,
        params
    );
}

// 获取检测结果数量  /v1/evidence/obtain/summary/'+detectId
export function queryMonitorNum(detectId) {
    return request(
        'get',
        '/v1',
        `/evidence/obtain/summary/${detectId}`
    );
}

// 获取检测结果详情列表
export function queryMonitor(pageNumber, pageSize, orderId) {
    return request(
        'get',
        '/v1',
        `/evidence/obtain/${orderId}/${pageNumber}-${pageSize}`
    );
}

// 一键取证
// v1/evidence/obtain/chain/2334-234-230      POST方法，最后一个参数是取证订单的orderNo
export function goObtain(orderNo) {
    return request(
        'post',
        '/v1',
        `/evidence/obtain/chain/${orderNo}`
    );
}



export function paymentDetectOrder(orderNo) {
    return request(
        'post',
        '/v1',
        `/evidence/detect/payment/${orderNo}`, {}
    );
}


export function queryVipConfig() {
    return request(
        'get',
        '/v1',
        `/evidence/vip/config`
    );
}


export function queryVipInfo() {
    return request(
        'get',
        '/v1',
        `/evidence/vip`
    );
}

export function applyVip(params) {
    return request(
        'post',
        '/v1',
        `/evidence/vip`,
        params
    );
}

export function queryEvidenceSummary() {
    return request(
        'get',
        '/v1',
        `/evidence/summary`
    );
}

// 获取买单，卖单，挂卖数量
export function queryShopUserSummary() {
    return request(    'get',     '/v1',     `/shop/user/summary`  );
}

// 新增发布挂卖获取productId
export function applyProduct(params) {
    return request(    'post',     '/v1',     `/shop/product/create`,
        params  );
}

// 提交发布挂卖信息
export function applyGood(params) {
    return request(    'post',     '/v1',     `/shop/goods/create`,
        params  );
}

// DetailView里面获取商品信息/shop/info/${goodsId} get
export function getGoodInfo(goodsId) {
    return request(    'get',     '/v1',     `/shop/goods/info/${goodsId}`,   );
}

// 下架挂卖信息
export function offShelfGood(goodsId, goodsStatus) {
    return request(    'get',     '/v1',     `/shop/goods/enable/${goodsId}/${goodsStatus}`,   );
}


// 首页存证记录数量信息
export function queryExistEvidenceSummary(params) {  
    return request(    'post',     '/v1',     `/evidence/summary/exist`,     params  );
}
// v1/evidence/exist/summary
export function queryExistEvidenceSummary2() {
    return request(
        'post',
        '/v1',
       `/evidence/exist/summary`
    );
}

// 首页存证记录 => 存证列表
export function queryAllExistEvidence(pageNumber, pageSize, params) {  
    return request(    'post',     '/v1',     `/evidence/exist/all/${pageNumber}-${pageSize}`,     params  );
}


// 论文查重
export function queryRepetition(params) {
    return request(
        'post',
        '/v1',
        `/paper/repetition`,
        params
    );
}
// 论文查重支付 paper/payment/order/${orderNo}
export function paymentRepetition(orderNo) {
    return request(
        'get',
        '/v1',
        `/paper/payment/order/${orderNo}`
    );
}
