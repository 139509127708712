export default {
    request: {
        SignatureKey: '4b424e19fd7287dc2378271f985bd0b8'
    },
    paymode: {
        'wechat': '微信',
        'alipay': '支付宝',
        'vip': '会员',
    },
    creationType: [{
            value: "1",
            label: "美术作品登记",
        },
        {
            value: "2",
            label: "文学作品登记",
        },
        {
            value: "3",
            label: "音乐作品登记",
        },
        {
            value: "4",
            label: "口述作品登记",
        },
        {
            value: "5",
            label: "视频作品登记",
        },
        {
            value: "6",
            label: "其它作品登记",
        },
    ],
    creationNature: [{
            value: "1",
            label: "原创",
        },
        {
            value: "2",
            label: "改编",
        },
        {
            value: "3",
            label: "翻译",
        },
        {
            value: "4",
            label: "汇编",
        },
        {
            value: "5",
            label: "注释",
        },
    ],
    publishStatus: [{
            value: "1",
            label: "未发表",
        },
        {
            value: "2",
            label: "已发表",
        },
    ],
    ownership: [{
            value: "1",
            label: "个人作品",
        },
        {
            value: "2",
            label: "法人作品",
        },
        {
            value: "3",
            label: "合作作品",
        },
        {
            value: "4",
            label: "职务作品",
        },
        {
            value: "5",
            label: "委托作品",
        },
    ],
    right: [{
            value: "1",
            label: "发表权",
        },
        {
            value: "2",
            label: "著名权",
        },
        {
            value: "3",
            label: "修改权",
        },
        {
            value: "4",
            label: "复制权",
        },
        {
            value: "5",
            label: "发行权",
        },
        {
            value: "6",
            label: "出租权",
        },
        {
            value: "7",
            label: "展览权",
        },
        {
            value: "8",
            label: "表演权",
        },
        {
            value: "9",
            label: "放映权",
        },
        {
            value: "10",
            label: "广播权",
        },
        {
            value: "11",
            label: "摄制权",
        },
        {
            value: "12",
            label: "改编权",
        },
        {
            value: "13",
            label: "翻译权",
        },
        {
            value: "14",
            label: "汇编权",
        },
        {
            value: "15",
            label: "其他",
        },
    ],
    platform: [
        // { label: '百度', value: 1 },
        // { label: '腾讯', value: 2 },
        // { label: '网易', value: 3 },
        // { label: '头条', value: 4 },
        // { label: '长江日报', value: 5 },
        // { label: '人民网', value: 6 },
        { label: '百度', value: 'BAIDU' },
      { label: '腾讯', value: 'TENCENT' },
      { label: '网易', value: 'NETEASE' },
      { label: '头条', value: 'TOUTIAO' },
      { label: '长江日报', value: 'CJN' },
      { label: '人民网', value: 'PEOPLE' },
    ],
    paperType: [
        { value: "1", label: "博士论文" },
        { value: "2", label: "硕士论文" },
        { value: "3", label: "本科论文" },
        { value: "4", label: "专科论文" },
        { value: "5", label: "发表、投稿论文" },
        { value: "6", label: "评审职称论文" },
        { value: "7", label: "图书专著论文" },
        { value: "8", label: "科研成课论文" },
        { value: "9", label: "项目基金论文" },
        { value: "10", label: "结项报告论文" },
        { value: "11", label: "专利论文" },
        { value: "12", label: "其他" },
    ],
    vipType: {
        'S': '白银会员',
        'G': '黄金会员',
        'D': '钻石会员',
    }
};