/**
 *head 导航栏历史记录mennus
 **/
const state = {
  activeManue:0,
}
const getters = {
  activeManue(state) {
    return state.activeManue
  }
}
const mutations = {
  setActiveManue(state, idx) {
    state.activeManue = idx
  }
}
export default {
  state,
  getters,
  mutations
}
