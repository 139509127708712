import Cookies from 'js-cookie'
var uuid = require('node-uuid')

const TokenKey = 'website-token'
const TermialCodeKey = 'terminalCode'
const MidKey = 'mid'

// export function setToken(token){
  
//   return sessionStorage.setItem(TokenKey,token);//设置数据
// }

// export function getToken(){
//   return sessionStorage.getItem(TokenKey);//取出数据
// }

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  //return Cookies.set(TokenKey, token,{ expires: new Date(new Date().valueOf()+600000) }) //10分钟过期时间
  return Cookies.set(TokenKey, token) //10分钟过期时间
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setTerminalCode(terminalCode) {
  return Cookies.set(TermialCodeKey, terminalCode)
}

export function getTerminalCode() {
  let code= Cookies.get(TermialCodeKey)||uuid.v1();
  setTerminalCode(code);
  return code;
}

export function getMid() {
  return Cookies.get(MidKey)
}

export function removeMid() {
  return Cookies.remove(MidKey)
}

export function setMid(mid) {
  return Cookies.set(MidKey, mid) //10分钟过期时间
}