<template>
  <div class="commonFoot">
    <div class="container"> 
        <div class="links"> 
             <router-link :to="{path:'/HelpCenter/CopyRight'}">用户指南</router-link>
              |
             <router-link :to="{path:'/FunctionDescription'}">功能说明</router-link>
              |
             <router-link :to="{path:'/LawService'}">法律服务</router-link>
              |
            <router-link :to="{path:'/ContactUs'}">联系我们</router-link>
        </div>
        <div class="copyRight"> 
          ©2022 中国知链 版权所有 保留一切权利
        </div>
          <a href="https://beian.miit.gov.cn/" target="_blank" class="text-light-gray">ICP备案：鄂ICP备2021022303号-2</a> 
     </div>
  </div>
</template>

<script>
export default {
  name: 'FootBlock',

  data () {
    return {
    }
  },

  methods: {}
}
</script>

<style lang='scss'>
    .commonFoot{
        background:#04397F;
        text-align: center;
        padding:32px 0 24px;
        .links{
           color:rgba(255,255,255,.2);
            a{
                color:rgba(255,255,255,.85);
                margin:0 8px;
                text-decoration: none;
            }
            a:hover{
              color:rgba(255,255,255,1);
            }
        }
        .copyRight{
          color:rgba(255,255,255,.45);
          border-top:solid 1px rgba(255,255,255,.1);
          padding-top:24px;
          margin-top:24px;
        }
        .text-light-gray{
          color:rgba(255,255,255,.45);
        }
    }
</style>
