<template>
<div class="header">
  <!-- <div class="headTop">
    <div class="container">
        <div>北斗卫星授时 ：{{time}}</div>
        <div class="callUs">欢迎致电：<a href="tel:027-87638980">027-87638980</a></div>
    </div>
  </div> -->
  <div class="commonHead" :class="{'opened':opened}">
    <div class="container headWrap"> 
        <a-icon type="menu" class="menuIcon" @click="opened=!opened"  v-if="!opened"/>
        <a-icon type="close" class="menuIcon" @click="opened=!opened" v-else/>
         <div class="manueWrap">
             <router-link :to="{path:'/Home'}"><h1 class="logo"> 中国知链 </h1></router-link>
         </div>
         <div class="outUl_div">
            <div class="container">
              <ul class="outUl">
                <li v-for="(item,index) in manue" :key="item.key" :class="{'active':activeIdx===item.key,'liOpened':item.childOpened}" @click="outerTiggle(index)" event="mouseover">
                  <div>{{item.label}}
                    <a-icon v-if="!!item.children" type="down"/> 
                  </div>
                  <ul v-if="!!item.children"  class="ant-dropdown-menu">
                      <li v-for="(itemChild,indexChild) in item.children" :key="indexChild" class="ant-dropdown-menu-item" @click="goto(itemChild)">{{itemChild.label}}</li>
                  </ul>
                </li>
                <!-- <li v-if="userInfo!=null" :class="{'liOpened':liOpenedCurrent}" @click="addLIOpened()" event="mouseover">
                  <div>个人中心
                    <a-icon type="down"/> 
                  </div>
                  <ul class="ant-dropdown-menu userUl">
                    <li v-for="(item,index) in centerLink" :key="index" class="ant-dropdown-menu-item" @click="goto(item)"> {{item.label}}</li>
                    <li class="ant-dropdown-menu-item" @click="onLogout">退出</li>
                  </ul>
                </li> -->
              </ul>
            </div>
          </div>
         <div class="rightWrap">
           <div class="rightTop">
              <router-link class="search" :to="{path:'/Search',query: {}}">
                <a-icon type="search"/>
              </router-link>
              <router-link class="bell" :to="{path:'/Mine/Notice',query: {}}">
                <a-icon type="bell" />
              </router-link>
              <template v-if="userInfo!=null">
                  <!-- <div class="searchIputDiv">
                    <a-input class="searchIput"></a-input>
                    <router-link class="searchIputBtn" :to="{path:'/Search',query: {}}">
                      <img src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/searchIput.svg" alt=""><span>查询</span>
                    </router-link>
                  </div> -->
                  <div class="userWrap">
                      <img src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/iconheadmine.svg">
                      <a @click="goto({name:'个人中心',router:'/Mine/BaseInfo',key:'MineBaseInfo'})"> {{userInfo.mobile}} </a>
                      <a-icon class="arrowDown" type="caret-down" />
                      <ul class="ant-dropdown-menu detailLayout">
                        <li v-for="(item,index) in centerLink" :key="index" class="ant-dropdown-menu-item" @click="goto(item)"> {{item.label}}</li>
                        <li class="ant-dropdown-menu-item" @click="onLogout">退出</li>
                      </ul>
                  </div>
              </template>
              <template v-else>
                <!-- <div class="searchIputDiv">
                  <a-input class="searchIput"></a-input>
                  <router-link class="searchIputBtn" :to="{path:'/Search',query: {}}">
                    <img src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/searchIput.svg" alt=""><span>查询</span>
                  </router-link>
                </div> -->
                <a-button class="login" @click="goto({name:'登录',router:'/Login',key:'Login'})">
                  登录
                </a-button>
                  <a-button class="sign" @click="goto({name:'注册',router:'/Rigist',key:'Rigist'})">
                  注册
                </a-button>
              </template>
           </div>
            <div class="clearfix"></div>
         </div>
     </div>
     <div class="outUl_div" style="display: none;">
       <div class="container">
         <ul class="outUl">
          <li v-for="(item,index) in manue" :key="item.key" :class="{'active':activeIdx===item.key,'liOpened':item.childOpened}" @click="outerTiggle(index)" event="mouseover">
            <div>{{item.label}}
              <a-icon v-if="!!item.children" type="down"/> 
            </div>
            <ul v-if="!!item.children"  class="ant-dropdown-menu">
                <li v-for="(itemChild,indexChild) in item.children" :key="indexChild" class="ant-dropdown-menu-item" @click="goto(itemChild)">{{itemChild.label}}</li>
            </ul>
          </li>
          <!-- <li v-if="userInfo!=null" :class="{'liOpened':liOpenedCurrent}" @click="addLIOpened()" event="mouseover">
            <div>个人中心
              <a-icon type="down"/> 
            </div>
            <ul class="ant-dropdown-menu userUl">
              <li v-for="(item,index) in centerLink" :key="index" class="ant-dropdown-menu-item" @click="goto(item)"> {{item.label}}</li>
              <li class="ant-dropdown-menu-item" @click="onLogout">退出</li>
            </ul>
          </li> -->
        </ul>
       </div>
     </div>
  </div>

  <el-dialog
  title="提示"
  :visible.sync="dialogVisible"
  width="350px"
>
  手机暂不支持此功能，请使用电脑打开网页！
如需了解更多功能，请查看此链接
  <a @click="goto({name:'演示视频',router:'/FunctionDescription',key:'FunctionDescription'})">http://cnpkp.ikedou.net/FunctionDescription</a>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="goto({name:'演示视频',router:'/FunctionDescription',key:'FunctionDescription'})">确 定</el-button>
  </span>
</el-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {logout} from '@/api/UserService'
export default {
  name: 'HeadBlock',
  props: {
    // 当前激活导航
    activeIdx: {
      type: [Number,String],
      default: 0
    }
  },
  data () {
    return {
      dialogVisible:false,
      logined:true,
      opened:false,
      liOpenedCurrent: false,
      time:'',
       manue:[
          {
            label:'首页',
            router:'/Home',
            key:'Home'
          },
          {
              label:'版权存证',
              router:'/ExistingEvidence/InputForm',
              key:'ExistingEvidenceInputForm'
          },
          {
            label:'搜索原创',
            router:'/Search',
            key:'Search'
          },
          // {
          //   label:'中国知链',
          //   router:'/IpChain',
          //    key:'IpChain',
          // },
          {
            label:'节点授权',
            router:'/NodeAuthorization',
             key:'NodeAuthorization',
          },
          {
            label:'综合服务',
            router:'/multipleService',
            childOpened:false,
            key:'',
            children:[
              {
                 label:'DCI版权',
                 router:'/multipleService',
                 key:'multipleService'
              },
              {
                label:'论文首发',
                router:'/PaperStarting',
                key:'PaperStarting',
              },
              {
                label:'论文查重',
                router:'/PaperCheck',
                key:'PaperCheck',
              },
              {
                label:'论文认证',
                router:'/PaperProve',
                key:'PaperProve',
              },
              {
                label:'论文送审',
                router:'/PaperSubmit',
                key:'PaperSubmit',
              },
              {
                label:'人才培养',
                router:'/Training',
                key:'Training',
              },
              {
                label:'法律服务',
                router:'/LawService',
                key:'LawService',
              },
              // {
              //    label:'VIP会员',
              //    router:'/Vip',
              // },
            ]
          },
          {
            label:'新闻资讯',
            router:'/NewsList',
            key:'NewsList',
          },
          {
            label:'关于我们',
            router:'/ContactUs',
              key:'ContactUs',
              childOpened:false,
              children:[
              {
                 label:'项目介绍',
                 router:'/AboutUs/Introduce',
                 key:'Introduce'
              },
              {
                label:'知识产权',
                router:'/AboutUs/IntellectualProperty',
                key:'IntellectualProperty',
              },
              {
                label:'发展规划',
                router:'/AboutUs/Development',
                key:'Development',
              },
              {
                label:'合作单位',
                router:'/AboutUs/Cooperation',
                key:'Cooperation',
              },
              {
                label:'联系我们',
                router:'/AboutUs/Contact',
                key:'Contact',
              },
              {
                label:'免责声明',
                router:'/AboutUs/Disclaimers',
                key:'Disclaimers',
              },
            ]
          },
       ],
      centerLink:[
        {
           label:'个人信息',
            router:'/Mine/BaseInfo',
            key:'MineBaseInfo'
        },
        {
           label:'实名认证',
            router:'/Mine/RealName',
            key:'MineRealName'
        },
        {
           label:'权利人管理',
            router:'/Mine/Obligee',
            key:'Mine'
        },
        {
           label:'我的钱包',
            router:'/Mine/MoneyBag',
            key:'MineObligee'
        },
        {
           label:'我的存证',
            router:'/Mine/ExistingEvidence',
            key:'MineExistingEvidence'
        },
        {
           label:'我的登记',
            router:'/Mine/MyRegister',
            key:'MineMyRegister'
        },
        {
           label:'我的监测',
            router:'/Mine/Monitor',
            key:'MineMonitor'
        },
        {
           label:'我的取证',
            router:'/Mine/ObtainEvidence',
            key:'MineObtainEvidence'
        },
        {
           label:'合约管理',
            router:'/Mine/OnSale',
            key:'MineOnSale'
        }
      ]

    }
  },
  created(){
    this.refreshTime();
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    outerTiggle(idx){
        const item= this.manue[idx]
        this.manue[idx].childOpened= !item.childOpened
        if(!item.children){
            this.goto(item)
        }
    },
    addLIOpened(){
      this.liOpenedCurrent = !this.liOpenedCurrent;
    },
    goto(item){
      const windowWidth=document.body.clientWidth 
      if(windowWidth<992&&(item.key=="MineBaseInfo"||item.key=="ExistingEvidenceInputForm")){
        this.dialogVisible=true
        return
      }
      this.$router.push({
            path: item.router,
            query: {}
          });
      this.opened=false
      this.dialogVisible=false
    },
    // 外部跳链
    // goPaper(item){
    //   if(item == '论文首发'){
    //     window.open("https://paper.cnpkp.com/",'_blank')
    //   }
    // },
    refreshTime(){
      let that=this;
      let refreshTimeText=()=>{
        that.time=this.dayjs().format('YYYY-MM-DD HH:mm:ss:SSS ');
      };
      setInterval(refreshTimeText,100);
    },
    onLogout(){
      let that=this;
       logout().then(({data,res})=>{
          that.$store.commit('logout');
         window.location.replace(window.location.protocol + "//"+window.location.host)
      });
    }
  }
}
</script>

<style lang='scss' scoped>
.header{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 84px;
  box-shadow: 0px 2px 10px 0px rgba(3,5,85,0.2);
  background: #0000CB;
}
.headTop{
  background: #04397F;
    .container{
         color:#fff;
        line-height: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        a{
          color:#fff;
        }
    }
  
}
  .commonHead{
      color:rgba(231, 202, 202, 0.75);
      font-size: 16px;
      height: 84px;
     .headWrap{
      //  display: flex;
      //  justify-content: space-between;
      //  align-items: center;
     }
     .outUl_div{
       float: left;
       height: 84px;
       .outUl{
         float: left;
              display: flex;
              align-items: center;
    justify-content: space-between;
              &>li{
                position: relative;
                height: 84px;
                line-height: 84px;
                text-align: center;
                &>div{
                  cursor: pointer;
                  line-height: 82px;
                  color: #fff;
                  font-size: 16px;
                  padding: 0 13px;
                }
                ul{
                  position: absolute;
                  left: 0px;
                  top:84px;
                  display: none;
                  text-align: center;
                  z-index: 1000;
                }
                li{
                  color:#002329;
                  font-size: 14px;
                  padding: 5px 24px;
                }
                ul.userUl{
                  li{
                    padding: 5px 18px;
                  }
                }
                li:hover{
                  background: #F2F8FF;
                }
              }
              &>li:hover{
                div{
                  opacity: 0.8;
                }
                ul{
                  display: block;
                }
              }
              .active {
                div{
                  opacity: 0.8;
                  border-bottom: 2px solid #fff;
                  font-weight: bold;
                  color:#fff;
                }
              }
              .anticon{
                font-size: 8px;
                position: absolute;
                top: 38px;
                right: 0px;
              }
            }
     }
     .menuIcon{
       display: none;
       line-height: 44px;
       font-size: 24px;
       font-weight: bold;
     }
      .manueWrap{
        float: left;
        height: 84px;
        display: flex;
        align-items: center;
        padding:10px 0;
        .logo{
          width:170px;
          height: 50px;
          //  background:url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/logoTextimg.svg') no-repeat;
           background:url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/logo.png') no-repeat center left;
           background-size:auto 44px;
           line-height: 9999em;
           overflow: hidden;
        }
      }
        .rightWrap{
          float: right;
          height: 84px;
          .clearfix{
            clear: both;
          }
          .rightTop{
            float: right;
            height: 84px;
            display: flex;
            align-items: center;
            padding: 10px 0;
            &>*{
            margin-left: 16px;
          }
          .anticon{
            font-size: 20px;
            color:rgba(255,255,255,.75)
          }
          /deep/ .ant-btn-background-ghost:hover, /deep/ .ant-btn-background-ghost:focus{
              color:rgba(0, 206, 206,.75);
              border-color:rgba(0, 206, 206,.75);
          }
            .searchIputDiv{
              display: flex;
              justify-content: space-between;
              align-items: center;
              .searchIput{
                border-color: #0A6ACF;
                height: 38px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
              .searchIputBtn{
                background: #0A6ACF;
                width: 120px;
                height: 38px;
                text-align: center;
                text-decoration: none;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                img{
                  width: 20px;
                  height: 20px;
                  vertical-align: middle;
                  margin-right: 5px;
                  margin-top: -3px;
                }
                span{
                  display: inline-block;
                  color: #fff;
                  vertical-align: middle;
                  line-height: 38px;
                }
              }
            }
            button{
              display: block;
                height: 38px;
                color: #fff;
                background: transparent;
                border: none;
                margin-left: 0;
                font-size: 16px;
            }
            .login{
              position: relative;
            }
            .login::after{
              content: '';
              width: 1px;
              height: 16px;
              background: #fff;
              position: absolute;
              top: 12px;
              right: 0px;
            }
          }
        }

        .userWrap{
          display: flex;
          align-items: center;
          position: relative;
          color: #fff;
          height: 84px;
          img{
            width:20px;
            height: 20px;
            margin-right: 8px;
            color: #fff;
          }
          span{
            color: #0A6ACF;
          }
          a{
            text-decoration: none;
            // color: #0A6ACF;
            color: #fff;
          }
          .anticon{
            font-size: 14px;
            margin-left: 4px;
            svg{
              // color: #0A6ACF;
              color: #fff;
            }
          }
          .detailLayout{
            display: none;
            position: absolute;
            top: 84px;
            right:0;
            text-align: center;
            z-index: 1001;
            li{
              padding: 5px 18px;
            }
            li:hover{
              background: #F2F8FF;
            }
          }
        }
        .userWrap:hover{
          .detailLayout{
            display: block;
          }
        }
  }
  
@media (min-width:992px)  {
  .commonHead {
      .rightWrap{
        .sign,.login{
            
        } 
      }
  }
}

@media screen and (min-width: 1px) and (max-width: 1200px){
  .headTop .container{
    justify-content: center;
    .callUs{
      display: none;
    }
  }
  .header{
    height: 84px;
  }
  .commonHead {
    height: 84px;
      .headWrap{
        display: block;
        height: 84px;
        min-height: 84px;
        padding:10px 0;
        position: relative;
         .outUl{
            display: none;
            width:100%;
             li{
               &>div:hover{
                  background: rgba(255,255,255,.1);
                }
             }
          }
      }
      .menuIcon{
        display: block;
        position: absolute;
        right:10px;
        top:22px;
        cursor: pointer;
      }
      .logo{
        position: absolute;
        left: 10px;
        top:16px;
      }
       .rightWrap{
            display: none;
            padding: 0 16px;
           .sign,.login{
            display: block;
            } 
        }
      .userWrap{
        .arrowDown{
          display: none;
        }
      }
      .userWrap:hover{
          .detailLayout{
            display: none;
          }
      }
  }
  .outUl_div{
    display: none;
  }
  .opened{
    position: relative;
    .rightWrap{
      display: none;;
      justify-content: flex-end;
      width: 100%;
      height: auto!important;
      background: #0000CB;
      z-index: 99;
      .rightTop{
        padding-bottom: 0;
      }
    }
    .manueWrap{
      justify-content: flex-end;
    }
    .outUl_div{
      display: block;
      width: 100%;
      height: auto!important;
      background: #0000CB;
      z-index: 99;
      margin-top: 74px;
        .outUl{
          display: block;
          text-align: right;
          margin-top: 0;
          padding: 10px 0;
        &>li{
          height: auto!important;
            div{
              line-height: 40px;
              text-align: right;
            }
            .anticon{
              top: 16px;
            }
            ul{
              position: static;
              background: none;
              box-shadow: none;
              border-top:solid 1px rgba(255, 255, 255,.1);
              border-bottom:solid 1px rgba(255, 255, 255,.1);
              border-radius: 0;
              li{
                color:rgba(255, 255, 255,.5);
                font-size: 14px;
                text-align: right;
              }
              li:hover{
                background: rgba(255,255,255,.1);
              }
            }
        } 
        &>li:hover{
            ul{
              display: none;
            }
        }
        &>li.liOpened{
          ul{
            display: block;
          }
        }
        }
    }
  }
}

</style>
