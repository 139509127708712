import Vue from 'vue'
import VueRouter from 'vue-router'
import * as auth from '@/utils/auth'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: {
            name: 'Home'
        }
    },
    // 首页
    {
        path: '/Home',
        name: 'Home',
        component: () =>
            import ('../views/Home.vue')
    },
    // 中国知链
    {
        path: '/IpChain',
        name: 'IpChain',
        component: () =>
            import ('../views/IpChain.vue')
    },
    {
        path: '/IpChain/:transactionHash/',
        name: 'IpChainWithHash',
        component: () =>
            import ('../views/IpChain.vue')
    },
    // 节点授权NodeAuthorization
    {
        path: '/NodeAuthorization',
        name: 'NodeAuthorization',
        component: () =>
            import ('../views/NodeAuthorization.vue')
    },
    // 新闻中心
    {
        path: '/NewsList',
        name: 'NewsList',
        component: () =>
            import ('../views/NewsList.vue')
    },
    // 登录
    {
        path: '/Login',
        name: 'Login',
        component: () =>
            import ('../views/loginRigist/Login.vue')
    },
    // 注册
    {
        path: '/Rigist',
        name: 'Rigist',
        component: () =>
            import ('../views/loginRigist/Rigist.vue')
    },
    // 重置密码
    {
        path: '/ForgetPwd',
        name: 'ForgetPwd',
        component: () =>
            import ('../views/loginRigist/ForgetPwd.vue')
    },
    // 注册协议
    {
        path: '/Agreement',
        name: 'Agreement',
        component: () =>
            import ('../views/loginRigist/Agreement.vue')
    },
    // 挂卖合约
    {
        path: '/Contract',
        name: 'Contract',
        component: () =>
            import ('../views/loginRigist/Contract.vue')
    },
    // 联系我们
    {
        path: '/ContactUs',
        name: 'ContactUs',
        component: () =>
            import ('../views/loginRigist/ContactUs.vue')
    },
    /**
     * 22.1.17新增 关于我们
     */
    {
        path: '/AboutUs',
        name: 'AboutUs',
        component: () =>
            import ('../views/AboutUs/index.vue'),
        children: [
            // 项目介绍
            {
                path: 'Introduce',
                name: 'Introduce',
                component: () =>
                    import ('../views/AboutUs/Introduce.vue'),
            },
            // 知识产权
            {
                path: 'IntellectualProperty',
                name: 'IntellectualProperty',
                component: () =>
                    import ('../views/AboutUs/IntellectualProperty.vue'),
            },
            // 发展规划
            {
                path: 'Development',
                name: 'Development',
                component: () =>
                    import ('../views/AboutUs/Development.vue'),
            },
            // 合作单位
            {
                path: 'Cooperation',
                name: 'Cooperation',
                component: () =>
                    import ('../views/AboutUs/Cooperation.vue'),
            },
            // 联系我们
            {
                path: 'Contact',
                name: 'Contact',
                component: () =>
                    import ('../views/AboutUs/Contact.vue'),
            },
            // 免责声明
            {
                path: 'Disclaimers',
                name: 'Disclaimers',
                component: () =>
                    import ('../views/AboutUs/Disclaimers.vue'),
            },
        ]
    },


    // 功能说明
    {
        path: '/FunctionDescription',
        name: 'FunctionDescription',
        component: () =>
            import ('../views/loginRigist/FunctionDescription.vue')
    },
    /** 
     * 综合服务 系列
     *  */
    // DCI服务介绍
    {
        path: '/multipleService',
        name: 'multipleService',
        component: () =>
            import ('../views/multipleService/index.vue')
    },
    // 论文首发
    {
        path: '/PaperStarting',
        name: 'PaperStarting',
        component: () =>
            import ('../views/multipleService/PaperStarting.vue')
    },
    // 论文查重
    {
        path: '/PaperCheck',
        name: 'PaperCheck',
        component: () =>
            import ('../views/multipleService/PaperCheck/index.vue'),
        children: [
            // 详情
            {
                path: 'Detail',
                name: 'PaperCheckDetail',
                meta: { auth: true },
                component: () =>
                    import ('../views/multipleService/PaperCheck/Detail.vue'),
            },
        ]
    },
    // 论文认证
    {
        path: '/PaperProve',
        name: 'PaperProve',
        component: () =>
            import ('../views/multipleService/PaperProve.vue')
    },
    // 论文送审
    {
        path: '/PaperSubmit',
        name: 'PaperSubmit',
        component: () =>
            import ('../views/multipleService/PaperSubmit.vue')
    },
    // 人才培养
    {
        path: '/Training',
        name: 'Training',
        component: () =>
            import ('../views/multipleService/Training/index.vue')
    },
    // 律师服务
    {
        path: '/LawService',
        name: 'LawService',
        component: () =>
            import ('../views/multipleService/LawService/index.vue')
    },
    // VIP会员
    {
        path: '/Vip',
        name: 'Vip',
        component: () =>
            import ('../views/multipleService/Vip/index.vue')
    },
    // 钱包》订单详情
    {
        path: '/OrderDetail',
        name: 'OrderDetail',
        component: () =>
            import ('../views/Mine/MoneyBag/OrderDetail.vue')
    },
    // 钱包》充值记录》充值详情
    {
        path: '/WithdrawalDetail',
        name: 'WithdrawalDetail',
        component: () =>
            import ('../views/Mine/MoneyBag/WithdrawalDetail.vue')
    },
    /**
     * 版权存证
     */
    {
        path: '/ExistingEvidence',
        name: 'ExistingEvidence',
        component: () =>
            import ('../views/multipleService/ExistingEvidence/index.vue'),
        children: [
            // 新增表单
            {
                path: 'InputForm',
                name: 'ExistingEvidenceInputForm',
                meta: { auth: true },
                component: () =>
                    import ('../views/multipleService/ExistingEvidence/InputForm/index.vue'),
            },
            // 我的存证-详情
            {
                path: 'Detail',
                name: 'ExistingEvidenceDetail',
                meta: { auth: true },
                component: () =>
                    import ('../views/multipleService/ExistingEvidence/Detail.vue'),
            },
            // 我的存证-查看存证文件
            {
                path: 'FileList',
                name: 'FileList',
                meta: { auth: true },
                component: () =>
                    import ('../views/multipleService/ExistingEvidence/FileList.vue'),
            },
        ]
    },
    /**
     * 版权登记
     */
    {
        path: '/Register',
        name: 'Register',
        component: () =>
            import ('../views/multipleService/Register/index.vue'),
        children: [
            // 新增表单
            {
                path: 'InputForm',
                name: 'RegisterInputForm',
                meta: { auth: true },
                component: () =>
                    import ('../views/multipleService/Register/InputForm/index.vue'),
            },
            // 我的登记-详情
            {
                path: 'Detail',
                name: 'RegisterDetail',
                meta: { auth: true },
                component: () =>
                    import ('../views/multipleService/Register/Detail.vue'),
            },
        ]
    },
    /**
     * 版权监测
     */
    {
        path: '/Monitor',
        name: 'Monitor',
        component: () =>
            import ('../views/multipleService/Monitor/index.vue'),
        children: [
            // 新增表单
            {
                path: 'InputForm',
                name: 'MonitorInputForm',
                meta: { auth: true },
                component: () =>
                    import ('../views/multipleService/Monitor/InputForm/index.vue'),
            },
            // 我的监测-详情
            {
                path: 'Detail',
                name: 'MonitorDetail',
                meta: { auth: true },
                component: () =>
                    import ('../views/multipleService/Monitor/Detail.vue'),
            },
            // 版权监测-监测结果
            {
                path: 'Result',
                name: 'MonitorResult',
                meta: { auth: true },
                component: () =>
                    import ('../views/multipleService/Monitor/Result.vue'),
            },
        ]
    },
    /**
     * 版权取证
     */
    {
        path: '/ObtainEvidence',
        name: 'ObtainEvidence',
        component: () =>
            import ('../views/multipleService/ObtainEvidence/index.vue'),
        children: [
            // 新增表单
            {
                path: 'InputForm',
                name: 'ObtainEvidenceInputForm',
                meta: { auth: true },
                component: () =>
                    import ('../views/multipleService/ObtainEvidence/InputForm/index.vue'),
            },
            // 我的取证-详情
            {
                path: 'Detail',
                name: 'ObtainEvidenceDetail',
                meta: { auth: true },
                component: () =>
                    import ('../views/multipleService/ObtainEvidence/Detail.vue'),
            },
        ]
    },
    // 挂卖表单
    {
        path: '/OnSale',
        name: 'OnSale',
        component: () =>
            import ('../views/multipleService/OnSale/index.vue'),
        children: [
            // 新增表单
            {
                path: 'InputForm',
                name: 'OnSaleForm',
                meta: {
                    auth: true,
                    keepAlive: true, //此组件需要被缓存
                },
                component: () =>
                    import ('../views/multipleService/OnSale/InputForm/index.vue'),
            },
            // 我的挂卖-详情
            {
                path: 'DetailView',
                name: 'DetailView',
                meta: { auth: true },
                component: () =>
                    import ('../views/multipleService/OnSale/DetailView.vue'),
            },
            {
                path: 'Detail',
                name: 'Detail',
                meta: { auth: true },
                component: () =>
                    import ('../views/multipleService/OnSale/Detail.vue'),
            },
            // 合约管理
            {
                path: 'ListDetail',
                name: 'ListDetail',
                meta: { auth: true },
                component: () =>
                    import ('../views/multipleService/OnSale/ListDetail.vue'),
            },
        ]
    },
    /**
     * 个人中心系列
     */
    {
        path: '/Mine',
        name: 'Mine',
        component: () =>
            import ('../views/Mine/index.vue'),
        children: [
            // 个人信息
            {
                path: 'BaseInfo',
                name: 'BaseInfo',
                meta: { auth: true },
                component: () =>
                    import ('../views/Mine/BaseInfo/index.vue'),
            },
            // 实名认证
            {
                path: 'RealName',
                name: 'RealName',
                meta: { auth: true },
                component: () =>
                    import ('../views/Mine/RealName/index.vue'),
            },
            // 权利人管理
            {
                path: 'Obligee',
                name: 'Obligee',
                meta: { auth: true },
                component: () =>
                    import ('../views/Mine/Obligee/index.vue'),
            },
            // 钱包
            {
                path: 'MoneyBag',
                name: 'MoneyBag',
                meta: { auth: true },
                component: () =>
                    import ('../views/Mine/MoneyBag/index.vue'),
            },
            // Vip
            {
                path: 'Vip',
                name: 'MineVip',
                meta: { auth: true },
                component: () =>
                    import ('../views/Mine/Vip/index.vue'),
            },
            // 我的存证
            {
                path: 'ExistingEvidence',
                name: 'MineExistingEvidence',
                meta: { auth: true },
                component: () =>
                    import ('../views/Mine/ExistingEvidence/index.vue'),
            },
            // 我的登记
            {
                path: 'MyRegister',
                name: 'MyRegister',
                meta: { auth: true },
                component: () =>
                    import ('../views/Mine/MyRegister/index.vue'),
            },
            // 我的监测
            {
                path: 'Monitor',
                name: 'MineMonitor',
                meta: { auth: true },
                component: () =>
                    import ('../views/Mine/Monitor/index.vue'),
            },
            // 我的取证
            {
                path: 'ObtainEvidence',
                name: 'MineObtainEvidence',
                meta: { auth: true },
                component: () =>
                    import ('../views/Mine/ObtainEvidence/index.vue'),
            },
            // 消息通知
            {
                path: 'Notice',
                name: 'Notice',
                meta: { auth: true },
                component: () =>
                    import ('../views/Mine/Notice/index.vue'),
            },
            // 合约管理
            {
                path: 'OnSale',
                name: 'MineOnSale',
                meta: {
                    auth: true,
                    // keepAlive: true, //此组件需要被缓存
                },
                component: () =>
                    import ('../views/Mine/OnSale/index.vue'),
            },
        ]
    },
    /**
     * 用户指南
     */
    {
        path: '/HelpCenter',
        name: 'HelpCenter',
        component: () =>
            import ('../views/HelpCenter/index.vue'),
        children: [
            // 版权相关
            {
                path: 'CopyRight',
                name: 'CopyRight',
                component: () =>
                    import ('../views/HelpCenter/CopyRight.vue'),
            },
            // 原本知识点
            {
                path: 'Knowledge',
                name: 'Knowledge',
                component: () =>
                    import ('../views/HelpCenter/Knowledge.vue'),
            },
            // 帮助中心
            {
                path: 'Help',
                name: 'Help',
                component: () =>
                    import ('../views/HelpCenter/Help.vue'),
            },
        ]
    },
    // 搜索
    {
        path: '/Search',
        name: 'Search',
        component: () =>
            import ('../views/Search.vue'),
        meta: {
            keepAlive: true, //此组件需要被缓存
        }
    },
    {
        path: '/certificate/:certificateCode',
        name: 'certificate',
        component: () =>
            import ('../views/certificate/index.vue')
    },
    // 首页存证记录跳转详情页
    {
        path: '/Record',
        name: 'Record',
        component: () =>
            import ('../views/Record/index.vue')
    },
]

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)

}

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // ...
    console.log(to)
    console.log(to.meta)
    if (to.meta.auth) {
        //需要登录
        if (auth.getMid()) {
            //
            next();
        } else {
            next({
                path: '/Login',
                query: { redirect: to.fullPath }
            })
        }
        // if(store)
    }
    next()
})

export default router