<template>
  <div id="app">
    <head-block :activeIdx="activeManue"></head-block>

    <keep-alive>
      <router-view v-if="$route.meta.keepAlive">
          <!-- 这里是会被缓存的视图组件 -->
      </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive">
        <!-- 这里是不被缓存的视图组件 -->
    </router-view>

    <foot-block></foot-block>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import HeadBlock from "@/components/common/HeadBlock";
import FootBlock from "@/components/common/FootBlock";
import { queryUserInfo } from "@/api/UserService";
import { queryPaymentPrice,queryVipConfig,queryVipInfo } from "@/api/EvidenceService";
import { queryObligeeList } from "@/api/ObligeeService";
export default {
  name: "App",
  components: {
    FootBlock,
    HeadBlock,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["activeManue", "mid", "userInfo"]),
  },
  mounted() {
    this.init();
    this.bus.$on("refreshVip", this.refreshVip); 
    this.bus.$on("refreshObligee", this.refreshObligee); 
    this.bus.$on("refreshInfo", this.refreshInfo); 
  },
  watch: {
    $route(to, from) {
      console.log(to.name);
      this.$store.commit("setActiveManue", to.name);
    },
  },
  methods: {
    init() {
      this.$store.commit("setActiveManue", this.$route.name);
      console.log("check user info");
      this.loadCommonData();
      if (this.mid && !this.userInfo) {
       this.refreshInfo();
        this.refreshVip();
        this.refreshObligee();
      }
    },
    refreshInfo(){
        if (this.mid) {
        queryUserInfo(this.mid).then(({ data, res }) => {
            this.$store.commit("setUserInfo", data);
          });
        }
    },
    refreshObligee(){
   if (this.mid) {
         //查询是否实名认证
        queryObligeeList(1, 1).then(({ data }) => {
          if (data && data.list.length > 0) {
            this.$store.commit("setAuthInfo", data.list[0]);
          }
        });
   }
    },
    refreshVip(){
          if (this.mid) {
          queryVipInfo().then(({ data }) => {
                  this.$store.commit("setVipInfo", data);
                });
          }
    },
    loadCommonData(){
        queryVipConfig().then(({ data }) => {
          this.$store.commit("setVipConfig", data);
        });

           queryPaymentPrice().then(({ data }) => {
            this.$store.commit("setEvidencePaymentPrice", data);
          });
    }
  },
};
</script>
<style lang="scss">
</style>
