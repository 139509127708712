import request from '@/utils/request'



export function createObligee(param) {
    return request(
        'post',
        '/v1',
       `/evidence/obligee`,
	   param
    );
}


export function queryObligeeList(pageNumber,pageSize) {
    return request(
        'post',
        '/v1',
       `/evidence/obligee/p/${pageNumber}-${pageSize}`
    );
}

export function queryObligeeDetail(obligeeCode) {
    return request(
        'get',
        '/v1',
       `/evidence/obligee/${obligeeCode}`
    );
}
